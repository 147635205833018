import styled from 'styled-components'

export const StyledDiv = styled.div`
	opacity: ${props => props.idle && props.idle.opacity};
	color: ${props => props.idle && props.idle.color};
	background: ${props => props.idle && props.idle.background};

	:hover {
		border-bottom-right-radius: ${props =>
			props.hover && props.hover.borderBottomRadius + 'px'};
		border-bottom-left-radius: ${props => props.hover && props.hover.borderBottomRadius + 'px'};
		opacity: ${props => props.hover && props.hover.opacity};
		color: ${props => props.hover && props.hover.color};
		background: ${props => props.hover && props.hover.background};
	}
`

export const StyledButton = styled.button`
	color: ${props => props.idleColor};
	border-color: ${props => props.idleBorder};
	background: ${props => props.idleBackground};
	opacity: 1;

	:hover {
		color: ${props => props.hoverColor};
		border-color: ${props => props.hoverBorder};
		background: ${props => props.hoverBackground};
		opacity: 0.75;
	}
	:active {
		color: ${props => props.activeColor};
		border-color: ${props => props.activeBorder};
		background: ${props => props.activeBackground};
		opacity: 1;
	}
`

export const StyledInput = styled.input`
	border-color: ${props => props.idleBorder};
	background: ${props => props.idleBackground};
	opacity: 1;

	:hover {
		border-color: ${props => props.hoverBorder};
		opacity: 0.75;
	}

	:focus {
		outline: none;
		background: ${props => props.focusedBackground};
		border-color: ${props => props.focusedBorder};
	}
`
export const StyledTextArea = styled.textarea`
	border-color: ${props => props.idleBorder};
	background: ${props => props.idleBackground};
	opacity: 1;

	:hover {
		border-color: ${props => props.hoverBorder};
		opacity: 0.75;
	}

	:focus {
		outline: none;
		background: ${props => props.focusedBackground};
		border-color: ${props => props.focusedBorder};
	}
`
