const _text = require('./text.js')
const isbot = require('node-isbot')
const _ = require('lodash')
const Parser = require('html-react-parser')

try {
	const toBlob = require('canvas-to-blob')
	toBlob.init()
} catch {
	console.log('canvas-to-blob failed to initialize!')
}

function _countryIVAMOSS(code, type) {
	// ! If changed, change in frontend/backend
	const pS = 'product_or_ship'
	const n = 'nut'

	if (type !== pS && type !== n) throw new Error('_countryIVAMOSS: Invalid type: ' + type)

	if (code === 'de') {
		if (type === pS) return 1.19
		if (type === n) return 1.07
	}
	if (code === 'at') {
		if (type === pS) return 1.2
		if (type === n) return 1.1
	}
	if (code === 'be') {
		if (type === pS) return 1.21
		if (type === n) return 1.06
	}
	if (code === 'bg') {
		if (type === pS) return 1.2
		if (type === n) return 1.09
	}
	if (code === 'hr') {
		if (type === pS) return 1.25
		if (type === n) return 1.05
	}
	if (code === 'dk') {
		if (type === pS) return 1.25
		if (type === n) return 1.25
	}
	if (code === 'sk') {
		if (type === pS) return 1.2
		if (type === n) return 1.1
	}
	if (code === 'si') {
		if (type === pS) return 1.22
		if (type === n) return 1.05
	}
	if (code === 'es' || code === 'es_continent' || code === 'es_baleares') {
		if (type === pS) return 1.21
		if (type === n) return 1.1
	}
	if (code === 'ee') {
		if (type === pS) return 1.2
		if (type === n) return 1.09
	}
	if (code === 'fi') {
		if (type === pS) return 1.24
		if (type === n) return 1.1
	}
	if (code === 'fr') {
		if (type === pS) return 1.2
		if (type === n) return 1.055
	}
	if (code === 'gr') {
		if (type === pS) return 1.24
		if (type === n) return 1.06
	}
	if (code === 'hu') {
		if (type === pS) return 1.27
		if (type === n) return 1.05
	}
	if (code === 'it') {
		if (type === pS) return 1.22
		if (type === n) return 1.05
	}
	if (code === 'lt') {
		if (type === pS) return 1.21
		if (type === n) return 1.05
	}
	if (code === 'lu') {
		if (type === pS) return 1.17
		if (type === n) return 1.08
	}
	if (code === 'mt') {
		if (type === pS) return 1.18
		if (type === n) return 1.05
	}
	if (code === 'nl') {
		if (type === pS) return 1.21
		if (type === n) return 1.09
	}
	if (code === 'pl') {
		if (type === pS) return 1.23
		if (type === n) return 1.05
	}
	if (code === 'cz') {
		if (type === pS) return 1.21
		if (type === n) return 1.1
	}
	if (code === 'ro') {
		if (type === pS) return 1.19
		if (type === n) return 1.05
	}
	if (code === 'se') {
		if (type === pS) return 1.25
		if (type === n) return 1.06
	}

	if (type === pS) return 1.23
	if (type === n) return 1.06
}

global.logCatch = function(err) {
	if (typeof err === 'object') {
		if (err.message) {
			console.log('\nMessage: ' + err.message)
		}
		if (err.stack) {
			console.log('\nStacktrace:')
			console.log('====================')
			console.log(err.stack)
		}
	} else {
		console.log('dumpError :: argument is not an object')
	}
	alert('Error catched! See console.log for details')
}
String.prototype.replaceAll = function(search, replacement) {
	var target = this
	return target.split(search).join(replacement)
}

const _prod =
	process.env.NODE_ENV === 'production' && !window.location.href.includes('herokuapp.com')
const _staging = !_prod && window.location.href.includes('staging.herokuapp.com')
const _dev = !_prod && window.location.href.includes('dev.herokuapp.com')
const _intercomAppID = _prod ? 'XXXX' : 'XXXX'
const _backendURL = _prod
	? 'https://www.be-slim.pt'
	: _staging
	? 'https://beslim-backend-staging.herokuapp.com'
	: _dev
	? 'https://beslim-backend-staging.herokuapp.com'
	: process.env.REACT_APP_DEV_BACKEND
const _privacyURL = 'XXXX'
const _termsURL = 'XXXX'
const _siteURL = _prod
	? 'https://www.be-slim.pt'
	: _staging
	? 'https://beslim-backend-staging.herokuapp.com'
	: _dev
	? 'https://beslim-backend-staging.herokuapp.com'
	: process.env.REACT_APP_DEV_BACKEND
const _googleAnalyticsID = _prod ? 'UA-30251585-2' : undefined
const _facebookPixelID = _prod ? '1552167601710959' : '409385046684062'

const moment = require('moment/min/moment-with-locales')
const detectBrowserLanguage = () =>
	(navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage
var botTest =
	'Mozilla/5.0 (Linux; Android 6.0.1; Nexus 5X Build/MMB29P) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.4515.119 Mobile Safari/537.36 (compatible; Googlebot/2.1; +http://www.google.com/bot.html)'
// ! This fix is not perfect since there can be a Google device name and it's a user, you should maybe just check for Googlebot
/* var browserLanguage =
	navigator.userAgent.includes('google') || navigator.userAgent.includes('Google')
		? 'pt'
		: detectBrowserLanguage().toLowerCase()
		global.lang = {
			text: browserLanguage.includes('pt') ? 'pt' : 'en',
			moment: browserLanguage.includes('pt') ? 'pt' : 'en',
			text: 'pt',
			moment: 'pt',
			numeral: 'us',
			date: browserLanguage.includes('pt') ? 'pt-PT' : 'en-US',
			date: 'pt-PT',
		} */
global.lang = {
	text: 'pt',
	moment: 'pt',
	numeral: 'us',
	date: 'pt-PT',
}
moment.locale(global.lang.moment)
var numeral = require('numeral')
require('numeral/locales')
numeral.register('locale', 'us', {
	delimiters: {
		thousands: '.',
		decimal: ',',
	},
	abbreviations: {
		thousand: 'K',
		million: 'M',
		billion: 'B',
		trillion: 'T',
	},
	ordinal: function(number) {
		return number === 1 ? 'er' : 'ème'
	},
	currency: {
		symbol: '$',
	},
})
numeral.locale(global.lang.numeral)

const _capitalize = s => {
	if (typeof s !== 'string') return ''
	return s.charAt(0).toUpperCase() + s.slice(1)
}

const _formatNumber = function(n, onlyPositive = false) {
	n = Number.parseFloat(n)

	if (onlyPositive && n < 0) n = 0

	if (n > 999999) return numeral(n).format('0.[0]a')
	else if (n > 9999) return numeral(n).format('0.[0]a')
	else return numeral(n).format('0')
}
const _formatDecimal = function(n, onlyPositive = false) {
	n = Number.parseFloat(n)

	if (onlyPositive && n < 0) n = 0

	return numeral(n).format('0.0')
}
const _formatDecimalTwo = function(n, onlyPositive = false) {
	n = Number.parseFloat(n)

	if (onlyPositive && n < 0) n = 0

	return numeral(n).format('0.00')
}

const _generateThumbnail = function(file, maxRes, callback) {
	if (file) {
		var reader = new FileReader()
		reader.onload = function(event) {
			var img = new Image()
			img.onload = function() {
				var oc = document.createElement('canvas')
				var octx = oc.getContext('2d')
				oc.width = img.width
				oc.height = img.height
				octx.drawImage(img, 0, 0)
				if (img.width > maxRes || img.height > maxRes) {
					if (img.width > img.height) {
						oc.height = (img.height / img.width) * maxRes
						oc.width = maxRes
					} else {
						oc.width = (img.width / img.height) * maxRes
						oc.height = maxRes
					}
				}
				octx.drawImage(oc, 0, 0, oc.width, oc.height)
				octx.drawImage(img, 0, 0, oc.width, oc.height)
				oc.toBlob(blob => {
					blob.lastModifiedDate = new Date()
					blob.name = file.name
					callback(blob)
				}, file.type)
			}
			img.src = event.target.result
		}
		reader.readAsDataURL(file)
	}
}
const _uploadFileHelper = function(file, callback, onProgress, ref, get, thumbURL, call) {
	var fileObject = { file: { type: file.type, size: file.size } }

	get(call + '?contentType=' + fileObject.file.type, true)
		.then(res => {
			var xhr = new XMLHttpRequest()
			xhr.upload.onprogress = function(e) {
				if (e.lengthComputable) {
					var percentComplete = ((e.loaded / e.total) * 100).toFixed(2)
					onProgress(percentComplete)
				}
			}.bind(ref)

			xhr.open('PUT', res.data.putURL)
			xhr.setRequestHeader('Cache-Control', 'public,max-age=3600')
			xhr.setRequestHeader('x-amz-acl', 'public-read')
			xhr.onload = function() {
				if (xhr.status === 200) {
					callback(true, res.data.getURL, thumbURL, file.name, file.type)
				}
			}.bind(ref)
			xhr.onerror = function() {
				alert('Could not upload file. Please try again!')
				callback(false)
			}.bind(ref)
			xhr.send(file)
		})
		.catch(e => {
			alert('Could not upload file. Please try again!')
			callback(false)
		})
}

const _propertiesToArray = function(obj) {
	var arr = []
	for (var x in obj)
		if (obj.hasOwnProperty(x)) {
			arr.push(obj[x])
		}
	return arr
}
const _propertyNamesToArray = function(obj) {
	var arr = []
	for (var x in obj)
		if (obj.hasOwnProperty(x)) {
			arr.push(x)
		}
	return arr
}

module.exports = {
	hotjar: {
		id: 2799852,
		version: 6,
	},
	countryIVAMOSS: _countryIVAMOSS,

	prod: _prod,
	staging: _staging,

	alfandega: 50,

	facebookPixelID: _facebookPixelID,
	googleAnalyticsID: _googleAnalyticsID,
	recaptchaSiteKey: 'XXXXXXXXX',

	backendURL: _backendURL + '/backend',
	websocketURL: _backendURL + '/client',
	privacyURL: _privacyURL,
	termsURL: _termsURL,
	siteURL: _siteURL,

	intercomAppID: _intercomAppID,
	intercomPaddingX: 35,
	intercomPaddingY: 30,

	mobileWidthTrigger: 801,

	minWidth: 768,
	publicMaxWidth: 1300,
	publicMinDesktopWidth: 1280,
	mobileWidth: '97vw',

	drawerWidth: 79,
	openDrawerWidth: 275,

	pagePaddingX: 35,
	pagePaddingY: 25,

	dashboardsGlobalMargin: 25,
	paddingTopGlobal: 13,

	teenAge: 18, // exclusive
	kidAge: 14, // exclusive
	minIMC: 18.5,
	maxIMC: 25,

	capitalize: _capitalize,
	formatNumber: _formatNumber,
	formatDecimal: _formatDecimal,
	formatDecimalTwo: _formatDecimalTwo,

	title: '',
	separator: '',
	landingTitle: ' | be-Slim',
	phrase: global.lang.text === 'pt' ? _text.site.title.pt : _text.site.title.en,
	domain: 'https://www.be-slim.pt',
	description: global.lang.text === 'pt' ? _text.site.description.pt : _text.site.description.en,

	spinnerSmall: { size: 28 * 0.66, border: 1.5 },
	spinnerMedium: { size: 28 * 1.5, border: 2 },
	spinnerLarge: { size: 28 * 3, border: 3 },

	checkDatesValidation: (year, day, month, hours, minutes) => {
		if (day && month && year) {
			var newDatesDaysMonthYears = month + '/' + day + '/' + year
			var newnewDate = moment(newDatesDaysMonthYears)

			var newDateHours = hours + ':' + minutes
			var newnewDateHours = moment(newDateHours, 'hh:mm')

			// diferença entre duas datas em termos de dias
			var diffDatesDaysMonthYears = newnewDate.diff(moment(), 'days')
			var diffDatesHours = newnewDateHours.diff(moment(), 'hours') + 1
			var diffDatesMinutes = newnewDateHours.diff(moment(), 'minutes')

			var istheSameDay = moment().isSame(newDatesDaysMonthYears, 'date')

			if (diffDatesDaysMonthYears > 0) {
				return true
			} else if (diffDatesDaysMonthYears === 0) {
				if (istheSameDay === false) {
					return true
				} else {
					if (diffDatesMinutes > 0) {
						return true
					} else if (diffDatesMinutes === 0) {
						return false
					}
				}
			}
		}
	},
	checkDatesValidationNoTime: (year, day, month) => {
		if (day && month && year) {
			var newDatesDaysMonthYears = month + '/' + day + '/' + year
			var newnewDate = moment(newDatesDaysMonthYears)

			// diferença entre duas datas em termos de dias
			var diffDatesDaysMonthYears = newnewDate.diff(moment(), 'days')

			var istheSameDay = moment().isSame(newDatesDaysMonthYears, 'date')

			if (diffDatesDaysMonthYears < 0) {
				return true
			} else if (diffDatesDaysMonthYears === 0) {
				if (istheSameDay !== false) {
					return true
				} else {
					return false
				}
			}
		}
	},

	handleImageChange: (
		e,
		callback,
		ref,
		nonImage = false,
		maxSize = 2000000 * 10,
		minWidth = 100,
		minHeight = 100
	) => {
		e.preventDefault()

		var file = e.target.files[0]

		if (file) {
			var isImage =
				file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/jpg'

			if (isImage || nonImage) {
				if (file.size > maxSize) {
					alert(
						(global.lang.text === 'pt' ? 'Ficheiro inválido' : 'Invalid file') +
							'\n' +
							(global.lang.text === 'pt'
								? 'O tamanho máximo é de 20 megabytes'
								: 'Maximum size is 20 megabytes')
					)
				} else {
					let reader = new FileReader()
					reader.onloadend = () => {
						if (nonImage && !isImage) {
							callback(file, reader.result)
						} else {
							var img = new Image()
							img.onload = function() {
								if (img.width < minWidth || img.height < minHeight) {
									alert(
										global.lang.text === 'pt'
											? 'Imagem inválida. Tem de ter pelo menos ' +
													minWidth +
													'px de largura e ' +
													minHeight +
													'px de altura'
											: 'Invalid image. The width needs to be bigger than ' +
													minWidth +
													'px and the height ' +
													minHeight +
													'px'
									)
								} else {
									callback(file, reader.result)
								}
							}
							img.src = reader.result
						}
					}
					reader.readAsDataURL(file)
				}
			} else {
				alert(
					(global.lang.text === 'pt'
						? 'Tipo de ficheiro inválido'
						: 'Invalid file type') +
						'\n' +
						(global.lang.text === 'pt'
							? 'Apenas PNG ou JPG são válidos'
							: 'Only PNG or JPG are valid')
				)
			}
		} else {
			alert(global.lang.text === 'pt' ? 'Ficheiro inválido' : 'Invalid file')
		}
	},
	uploadFile: (
		file,
		callback,
		onProgress,
		ref,
		get,
		thumbnailWidth,
		onlyThumbnail = false,
		nonImage = false,
		call = 'client/upload_url'
	) => {
		if (!thumbnailWidth) thumbnailWidth = 800

		var isImage =
			file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/jpg'

		if (nonImage && !isImage) {
			_uploadFileHelper(file, callback, onProgress, ref, get, undefined, call)
		} else {
			if (onlyThumbnail) {
				_generateThumbnail(file, thumbnailWidth, thumb => {
					_uploadFileHelper(thumb, callback, onProgress, ref, get, undefined, call)
				})
			} else {
				_generateThumbnail(file, thumbnailWidth, thumb => {
					_uploadFileHelper(
						thumb,
						(success, thumbURL) => {
							if (success) {
								_generateThumbnail(file, thumbnailWidth, img => {
									_uploadFileHelper(
										img,
										callback,
										onProgress,
										ref,
										get,
										thumbURL,
										call
									)
								})
							}
						},
						onProgress,
						ref,
						get,
						undefined,
						call
					)
				})
			}
		}
	},

	isEmail: email => {
		return !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)
	},

	somethingWrong: {
		title: global.lang.text === 'pt' ? 'Ocorreu um erro 😞' : 'Something went wrong 😞',
		text: global.lang.text === 'pt' ? '' : '',
	},

	calculateAge: function(birthday) {
		var ageDifMs = Date.now() - new Date(birthday).getTime()
		var ageDate = new Date(ageDifMs) // miliseconds from epoch
		return Math.abs(ageDate.getUTCFullYear() - 1970)
	},

	getTheTextObject: () => {
		return _text
	},
	text: (key, lang, replaces, parse = true, override = _text) => {
		var o

		if (lang) o = _.get(override, key + '.' + lang)
		else o = _.get(override, key + '.' + global.lang.text)

		if (replaces) {
			replaces.forEach(r => {
				if (o) o = o.replaceAll(r.key, r.value)
			})
		}

		if (o !== undefined) {
			if (parse) return Parser(o)
			else return o
		} else return 'STRING NOT FOUND! (' + key + ')'
	},
	htmlParse: o => {
		if (typeof o == 'string') return Parser(o)
		return o
	},
	localize: (obj, lang, upperCase = false) => {
		if (!obj) return 'OBJECT IS EMPTY!'

		if (upperCase && obj.pt) obj.pt = obj.pt.toUpperCase()
		if (upperCase && obj.en) obj.en = obj.en.toUpperCase()

		if (obj.en === undefined) return obj.pt
		if (lang) return Parser(obj[lang])
		else return Parser(obj[global.lang.text])
	},

	propertiesToArray: _propertiesToArray,
	propertyNamesToArray: _propertyNamesToArray,
}
