import React, { Component } from 'react'
import Line from './Line'
import MaterialIcon from 'material-icons-react'
import styles from '../assets/styles'

const config = require('../config')

export default class ModalHeader extends Component {
	render() {
		return (
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
				}}
			>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						padding: 15,
						paddingRight: 20,
						paddingLeft: 20,
						paddingBottom: 0,
						flexDirection: 'row',
						justifyContent: 'space-between',
						backgroundColor: this.props.color ? this.props.color : '',
					}}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'flex-start',
						}}
					>
						<div
							style={{
								...styles.header_small,
								...{
									fontSize: 16,
									color: this.props.customStyling.color
										? this.props.customStyling.color
										: styles.colors.black,
									textAlign: 'center',
									paddingBottom: 10,
									fontWeight: 700,
								},
							}}
						>
							{this.props.title}
						</div>
						<div
							style={{
								...styles.header_small,
								...{
									marginLeft: 5,
									fontSize: 16,
									color: this.props.customStyling.color
										? this.props.customStyling.color
										: styles.colors.black,
									textAlign: 'center',
									paddingBottom: 10,
									fontWeight: 700,
								},
							}}
						>
							{this.props.extraTitle}
						</div>
					</div>
					{!this.props.noClose && (
						<button
							style={{
								borderRadius: 5,
								marginLeft: 30,
								marginBottom: 10,
								height: 24,
							}}
							onClick={this.props.onClose}
						>
							<MaterialIcon icon='close' size='small' color={styles.colors.black} />
						</button>
					)}
				</div>
				{!this.props.noLine ||
					(this.props.customStyling.noLine && <Line color={this.props.color} />)}
			</div>
		)
	}
}
