import { css } from 'glamor'
import { createBrowserHistory } from 'history'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ReactPixel from 'react-facebook-pixel'
import ReactGA from 'react-ga'
//import TagManager from 'react-gtm-module'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import MediaQuery from 'react-responsive'
import { Redirect, Route, Router, Switch } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Cookies from 'universal-cookie'
import { QueryParamProvider } from 'use-query-params'
import styles from '../assets/styles'
import ScrollToTop from '../components/ScrollToTop'
import whatsapp from '../pages/dashboards/components/whatsapp.svg'
import QueryString from '../utils/queryString'
import { fetchUser } from './UserState'

//

const Forgot = React.lazy(() => import('../pages/auth/Forgot'))
const Login = React.lazy(() => import('../pages/auth/Login'))
const About = React.lazy(() => import('../pages/public/about/About'))
const Appointment = React.lazy(() => import('../pages/public/appointment/Appointment'))
const AppointmentDone = React.lazy(() => import('../pages/public/appointment/AppointmentDone'))
const Checkout = React.lazy(() => import('../pages/public/checkout/Checkout'))
const CheckoutEnd = React.lazy(() => import('../pages/public/checkout/CheckoutEnd'))
const Contact = React.lazy(() => import('../pages/public/contact/Contact'))
const EmailSettings = React.lazy(() => import('../pages/public/EmailSettings'))
const EndingForm = React.lazy(() => import('../pages/public/EndingForm'))
const Evaluation = React.lazy(() => import('../pages/public/evaluation/Evaluation'))
const Goals = React.lazy(() => import('../pages/public/goal/Goals'))
const Job = React.lazy(() => import('../pages/public/job/Job'))
const Main = React.lazy(() => import('../pages/public/main/Main'))
const Method = React.lazy(() => import('../pages/public/method/Method'))
const PleaseLogout = React.lazy(() => import('../pages/public/PleaseLogout'))
const Press = React.lazy(() => import('../pages/public/press/Press'))
const Programs = React.lazy(() => import('../pages/public/programs/Programs'))
const Startpoint = React.lazy(() => import('../pages/public/startpoint/Startpoint'))
const Store = React.lazy(() => import('../pages/public/store/Store'))
const Terms = React.lazy(() => import('../pages/public/terms/Terms'))
const Testimonials = React.lazy(() => import('../pages/public/testimonials/Testimonials'))
const DashConsult = React.lazy(() => import('../pages/dashboards/DashboardConsultant'))
const DashNutri = React.lazy(() => import('../pages/dashboards/DashboardNutrition'))
const DashUser = React.lazy(() => import('../pages/dashboards/DashboardUser'))

const cookies = new Cookies()
const config = require('../config')

var amountToasts = 0

class NavigatorView extends Component {
	constructor() {
		super()

		this.state.history = createBrowserHistory()
		global.routerHistory = function() {
			return this.state.history
		}.bind(this)
		this.state.history.listen(location => {
			this.props.fetchUser()
		})
	}

	state = {}

	updateColors = (reset, user) => {
		if (!global.colorsBackup) global.colorsBackup = JSON.parse(JSON.stringify(styles.colors))

		if (reset) {
			styles.colors.accent = global.colorsBackup.accent
			styles.colors.fadedOrange = global.colorsBackup.fadedOrange
			styles.colors.accentVeryLight = global.colorsBackup.accentVeryLight
			styles.colors.accentLight = global.colorsBackup.accentLight
			styles.colors.orange = global.colorsBackup.orange
		} else if (user) {
			if (user.account.accountType.code === 'user') {
				styles.colors.accent = styles.colors.accent
				styles.colors.fadedOrange = styles.colors.accentVeryLight
				styles.colors.accentVeryLight = styles.colors.accentVeryLight
				styles.colors.accentLight = styles.colors.accentLight
				styles.colors.orange = styles.colors.accent
			} else if (user.account.accountType.code === 'consultant') {
				styles.colors.accent = global.colorsBackup.accent
				styles.colors.fadedOrange = global.colorsBackup.fadedOrange
				styles.colors.accentVeryLight = global.colorsBackup.accentVeryLight
				styles.colors.accentLight = global.colorsBackup.accentLight
				styles.colors.orange = global.colorsBackup.orange
			} else if (user.account.accountType.code === 'nutritionist') {
				//André
				styles.colors.accent = styles.colors.yellow
				styles.colors.fadedOrange = styles.colors.yellow
				styles.colors.accentVeryLight = styles.colors.yellowVeryLight
				styles.colors.accentLight = styles.colors.yellow
				styles.colors.orange = styles.colors.yellow
			}
		}
	}

	componentWillMount() {
		var query = QueryString.parse(window.location.search)
		if (query.utm_source) {
			localStorage.setItem('utm_source', query.utm_source)
			localStorage.setItem('utm_medium', query.utm_medium)
			localStorage.setItem('utm_campaign', query.utm_campaign)
			localStorage.setItem('utm_term', query.utm_term)
			localStorage.setItem('utm_content', query.utm_content)
		}
		if (query.token_override) {
			localStorage.setItem('token', query.token_override)
		}

		var w = window.location.pathname + window.location.search

		const advancedMatching = {} // Optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
		const options = {
			autoConfig: true, // Send extra info automatically
			debug: !config.prod, // Wnable logs
		}
		ReactPixel.init(config.facebookPixelID, advancedMatching, options)
		ReactPixel.pageView()
		console.log('Facebook Pixel Pageview')

		if (config.googleAnalyticsID) {
			ReactGA.initialize(config.googleAnalyticsID)
		}
		ReactGA.plugin.require('ec')
		global.ReactGA = ReactGA
		ReactGA.pageview(w)
		console.log('Google Analytics Pageview: ' + w)

		//if (config.prod) {
		/* const tagManagerArgs = {
			gtmId: 'GTM-NB6QXB8',
		}
		TagManager.initialize(tagManagerArgs) */
		//}

		this.state.history.listen(location => {
			var l = location.pathname + location.search

			ReactPixel.pageView()
			console.log('Facebook Pixel Pageview')

			if (config.googleAnalyticsID) {
				ReactGA.pageview(l)
				console.log('Google Analytics Pageview: ' + l)
				try {
					var ck = cookies.get('_fbp')
					localStorage.setItem('fb_fbp', ck)
				} catch (e) {
					console.error(e.message)
				}
				try {
					var cook = cookies.get('_ga').split('.')
					localStorage.setItem(
						'ga_cid',
						cook[cook.length - 2] + '.' + cook[cook.length - 1]
					)
				} catch (e) {
					console.error(e.message)
				}
			}

			// ! TODO utms
			/* var query = QueryString.parse(window.location.search)
			TagManager.dataLayer({
				dataLayer: {
					event: 'pageChanged',
					url: window.location.pathname + window.location.search,
					utm_source: localStorage.getItem('utm_source'),
					utm_medium: localStorage.getItem('utm_medium'),
					utm_campaign: localStorage.getItem('utm_campaign')	,utm_content: localStorage.getItem(
																	'utm_content'
																),
				},
			}) */

			// TODO: Don't forget to support modals and other components that don't use the router
		})

		if (!window.location.pathname.includes('checkout_end')) {
			localStorage.setItem('linkID', '')
			localStorage.setItem('transactionType', '')
		}

		//

		this.socketNotification = this.socketNotification.bind(this)
		global.socket.on('notification', this.socketNotification)

		this.props.fetchUser(data => {})
		this.onUpdateData = this.onUpdateData.bind(this)
		global.socket.on('data_update', this.onUpdateData)

		/* this.listenToIframeMessege() */
	}
	componentDidMount() {
		this.state.history.listen(
			function(location) {
				this.updateColors(!location.pathname.includes('dashboard'), this.props.user)
			}.bind(this)
		)
	}
	/* listenToIframeMessege() {
		try {
			var eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent'
			var eventer = window[eventMethod]
			var messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message'

			eventer(messageEvent, function(e) {
				//if (e.origin !== 'http://www.be-slim.com.pt') return;

				if (
					e &&
					e.data &&
					e.data.realBlogURL &&
					window.location.pathname !== e.data.realBlogURL
				) {
					window.history.pushState('', window.document.title, e.data.realBlogURL)
				}
			})
		} catch (e) {
			console.error(e)
		}
	} */
	socketNotification(data) {
		this.addFlag(data.title, data.description, data.type, true)
	}
	onUpdateData() {
		this.props.fetchUser(data => {})
	}
	shouldComponentUpdate(nextProps, nextContext) {
		if (this.props.user !== nextProps.user && nextProps.user) {
			var user = nextProps.user

			this.updateColors(!this.state.history.location.pathname.includes('dashboard'), user)
			/* window.Intercom('update', {
				app_id: config.intercomAppID,
				name: user.personal.firstName + ' ' + user.personal.lastName, // Full name
				email: user.email, // Email address
				created_at: new Date(user.registerDate).getTime() / 1000, // Signup date as a Unix timestamp
				alignment: 'right',
				horizontal_padding: config.intercomPaddingX,
				vertical_padding: config.intercomPaddingY,
			}) */
		}

		return true
	}

	static childContextTypes = {
		addFlag: PropTypes.func,
	}
	getChildContext() {
		return {
			addFlag: this.addFlag,
		}
	}

	addFlag = (title, description, type, playSound = false) => {
		if (amountToasts > 5) return

		amountToasts++

		var t = toast.info
		if (type === 'error') t = toast.error
		if (type === 'warning') t = toast.warn
		if (type === 'success') t = toast.success

		t(
			<div style={{ padding: 5 }}>
				<b style={{ fontSize: 15 }}>{title}</b>
				<div style={{ minHeight: 10 }} />
				<p style={{ fontSize: 13, opacity: 0.75 }}>{description}</p>
				<div style={{ minHeight: 5 }} />
			</div>,
			{
				onClose: () => {
					amountToasts--
				},
				position: 'bottom-left',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				pauseOnVisibilityChange: false,
				pauseOnFocusLoss: false,
				draggable: true,
				className: css({
					borderRadius: 3,
					boxShadow: styles.strongerShadow,
					marginRight: config.pagePaddingX - 10,
					marginTop: config.pagePaddingY - 10,
					background: type === 'info' ? styles.colors.main : '',
				}),
				bodyClassName: css({
					//fontSize: '14px',
					fontFamily: styles.font,
					//color: styles.colors.black,
				}),
				/* progressClassName: css({
                    background: styles.gradients.main
                }) */
			}
		)
	}

	///////////////////////////////// Learn how to use router: https://codeburst.io/react-router-v4-unofficial-migration-guide-5a370b8905a

	render() {
		if (!this.props.ready) return <div></div>

		var admin = false
		var userType
		var DashboardComponent
		if (this.props.user) {
			admin = this.props.user.admin
			userType = this.props.user.account.accountType.code

			if (global.Sentry)
				global.Sentry.configureScope(
					function(scope) {
						scope.setUser({ email: this.props.user.email, id: this.props.user._id })
					}.bind(this)
				)

			if (userType === 'nutritionist') {
				var searchParams = new window.URLSearchParams(window.location.search)
				searchParams.set('utm_source', 'checkoutnutricionista')
				searchParams.set('utm_medium', 'vendanutricionista')
				searchParams.set('utm_campaign', 'vendanutricionista')
				var newRelativePathQuery = window.location.pathname + '?' + searchParams.toString()
				window.history.pushState(null, '', newRelativePathQuery)
			}

			var pathname = window.location.pathname
			var forceAdminSwitch =
				!pathname.includes('consultant') &&
				!pathname.includes('nutritionist') &&
				userType === 'consultant' &&
				!this.props.user.onlyTracking &&
				!this.props.user.calleebree &&
				!this.props.user.onlyMarketing &&
				!this.props.user.limitedAdmin &&
				localStorage &&
				localStorage.getItem('forceAdminSwitch')

			if (userType === 'consultant') {
				userType = forceAdminSwitch === 'nutritionist' ? 'nutritionist' : 'consultant'
			} else if (userType === 'nutritionist') {
				userType = forceAdminSwitch === 'consultant' ? 'consultant' : 'nutritionist'
			}

			if (userType === 'consultant') {
				DashboardComponent = DashConsult
			} else if (userType === 'nutritionist') {
				DashboardComponent = DashNutri
			} else if (userType === 'user') DashboardComponent = DashUser
		}

		if (userType === 'nutritionist') {
			if (pathname.includes('consultant')) {
				window.location.pathname = pathname.replace('consultant', 'nutritionist')
			}
		}

		return (
			<MediaQuery minWidth={config.mobileWidthTrigger}>
				{desktop => {
					if (global.tawk) {
						/* if (!desktop) { */
						if (this.state.history.location.pathname.includes('checkout')) {
							if (global.tawk.isChatHidden()) global.tawk.showWidget()
						} else if (!global.tawk.isChatHidden()) global.tawk.hideWidget()
						/* } else {
							if (this.state.history.location.pathname.includes('dashboard')) {
								if (!global.tawk.isChatHidden()) global.tawk.hideWidget()
							} else {
								if (global.tawk.isChatHidden()) global.tawk.showWidget()
							}
						} */
					}

					console.log('JAKIM ' + this.state.history.location.pathname)
					let showWhatsapp = false
					if (
						!this.state.history.location.pathname.includes('dashboard') &&
						!this.state.history.location.pathname.includes('checkout')
					) {
						if (
							desktop ||
							(this.state.history.location.pathname !== '/' &&
								this.state.history.location.pathname !== '/consulta')
						)
							showWhatsapp = true
					}

					return (
						<div>
							<Router history={this.state.history}>
								{showWhatsapp && (
									<div
										style={{
											position: 'fixed',
											bottom: desktop ? 20 : 65,
											right: desktop ? 20 : 12,
											zIndex: 1111,
										}}
									>
										<a
											style={{ opacity: 1 }}
											href={'https://wa.me/351913124833'}
											target='_blank'
										>
											<img
												style={{ height: 50, width: 50 }}
												src={whatsapp}
											></img>
										</a>
									</div>
								)}
								<ScrollToTop>
									<QueryParamProvider ReactRouterRoute={Route}>
										<Switch>
											<Route path='/dashboard'>
												<Helmet>
													<title>
														{config.title +
															config.separator +
															'Dashboard'}
													</title>
													<meta
														name='description'
														content={config.description}
													/>
												</Helmet>

												{this.props.user && (
													<Switch>
														<Route
															exact
															path={'/dashboard/' + userType}
															component={DashboardComponent}
														/>
														<Route
															exact
															path={
																'/dashboard/' +
																userType +
																'/:screen/:id'
															}
															component={DashboardComponent}
														/>
														<Route
															exact
															path={
																'/dashboard/' +
																userType +
																'/:screen'
															}
															component={DashboardComponent}
														/>
														<Route
															path={
																'/dashboard/' +
																userType +
																'/:screen/:id' +
																'/:screen/:id/:something'
															}
														>
															<Redirect
																to={'/dashboard/' + userType}
															/>
														</Route>

														{/* <Route exact path='/dashboard/chat' component={Chat} />
											<Route
												exact
												path='/dashboard/chat/:id'
												component={Chat}
											/>
											<Route path='/dashboard/chat/:id/:something'>
												<Redirect to={'/dashboard/chat'} />
											</Route> */}

														{/* {admin && (
												<Route
													exact
													path='/dashboard/admin'
													component={Admin}
												/>
											)}
											{admin && (
												<Route
													exact
													path='/dashboard/admin/:screen'
													component={Admin}
												/>
											)}
											{admin && (
												<Route path='/dashboard/admin/:screen/:something'>
													<Redirect to={'/dashboard/admin'} />
												</Route>
											)} */}

														<Route path='/'>
															<Redirect
																to={'/dashboard/' + userType}
															/>
														</Route>
													</Switch>
												)}
											</Route>

											<Route path='/login' component={Login} />
											{/* <Route path='/register' component={Register} /> */}
											<Route path='/forgot' component={Forgot} />

											<Route path='/sobre-a-be-slim' component={About} />
											<Route path='/metodo' component={Method} />
											<Route path='/testemunhos' component={Testimonials} />
											<Route
												path='/blog'
												render={() => {
													window.location.href = 'https://blog.be-slim.pt'

													/* <div>
											<iframe
												id='blogggg'
												style={{ width: '100vw', height: '100vh' }}
												src={
													'https://www.be-slim.com.pt' +
													window.location.pathname
												}
											></iframe>
										</div> */

													return <div></div>
												}}
											></Route>

											<Route
												exact
												path='/store'
												component={
													userType !== 'consultant' &&
													userType !== 'nutritionist'
														? Store
														: PleaseLogout
												}
											/>
											<Route
												exact
												path='/store/:screen/:id'
												component={
													userType !== 'consultant' &&
													userType !== 'nutritionist'
														? Store
														: PleaseLogout
												}
											/>
											<Route
												exact
												path='/store/:screen'
												component={
													userType !== 'consultant' &&
													userType !== 'nutritionist'
														? Store
														: PleaseLogout
												}
											/>

											<Route
												path='/email_setting_weight'
												component={EmailSettings}
											/>

											<Route path='/objectivos' component={Goals} />
											<Route path='/objectivos/emagrecer' component={Goals} />
											<Route
												path='/objectivos/perder-barriga'
												component={Goals}
											/>
											<Route
												path='/objectivos/gordura-localizada'
												component={Goals}
											/>
											<Route
												path='/objectivos/perder-peso'
												component={Goals}
											/>
											<Route
												path='/objectivos/gravidez-saudavel'
												component={Goals}
											/>
											<Route
												path='/objectivos/alimentacao-saudavel'
												component={Goals}
											/>
											<Route
												path='/objectivos/ganhar-peso'
												component={Goals}
											/>
											<Route
												path='/objectivos/ganhar-massa-muscular'
												component={Goals}
											/>
											<Route
												path='/objectivos/crianca-adolescente'
												component={Goals}
											/>
											<Route
												path='/objectivos/eliminar-celulite'
												component={Goals}
											/>

											<Route path='/programas-be-slim' component={Programs} />
											<Route
												path='/programas-be-slim/emagrecimento'
												component={Programs}
											/>
											<Route
												path='/programas-be-slim/pos-parto'
												component={Programs}
											/>
											<Route
												path='/programas-be-slim/anti-aging'
												component={Programs}
											/>
											<Route
												path='/programas-be-slim/homem'
												component={Programs}
											/>
											<Route
												path='/programas-be-slim/ex-fumador'
												component={Programs}
											/>
											<Route
												path='/programas-be-slim/pre-mama'
												component={Programs}
											/>
											<Route
												path='/programas-be-slim/alimentacao-saudavel'
												component={Programs}
											/>
											<Route
												path='/programas-be-slim/desporto'
												component={Programs}
											/>
											<Route
												path='/programas-be-slim/ganhar-peso'
												component={Programs}
											/>
											<Route
												path='/programas-be-slim/anti-celulite'
												component={Programs}
											/>
											<Route
												path='/programas-be-slim/adolescente'
												component={Programs}
											/>
											<Route
												path='/programas-be-slim/crianca'
												component={Programs}
											/>

											<Route path='/como-funciona' component={Startpoint} />
											<Route path='/emprego' component={Job} />
											<Route
												path='/consulta_done'
												component={AppointmentDone}
											/>
											<Route path='/consulta' component={Appointment} />
											<Route path='/contacto' component={Contact} />
											<Route path='/imprensa' component={Press} />
											<Route path='/terms' component={Terms} />
											<Route
												path='/evaluation'
												component={
													userType !== 'consultant' &&
													userType !== 'nutritionist'
														? Evaluation
														: PleaseLogout
												}
											/>
											<Route
												path='/checkout'
												component={
													userType !== 'consultant' &&
													userType !== 'nutritionist'
														? Checkout
														: PleaseLogout
												}
											/>
											<Route path='/checkout_end' component={CheckoutEnd} />

											<Route path='/ending_form' component={EndingForm} />

											<Route path='/' component={Main} />
										</Switch>
									</QueryParamProvider>
								</ScrollToTop>
							</Router>

							{!global.noFlags && <ToastContainer />}
						</div>
					)
				}}
			</MediaQuery>
		)
	}
}

export default connect(
	state => ({
		user: state.user.data,
	}),
	{ fetchUser }
)(NavigatorView)
