import './fonts.css'

const _font = 'Roboto'
const _fontAlt = 'Source Sans Pro'
const _background = '#f4f5f7'

const _black = '#252525'
const _white = 'rgb(254,254,254)' // Don't use pure white to avoid flickering

const _grey = 'rgba(110,110,110,1)'
const _greyLight = 'rgba(165,165,175,.75)'
const _greyVeryLight = 'rgba(110,110,110,.25)'

const _main = '#02A99C'
const _mainLight = '#3DBFBE'
const _mainVeryLight = '#EBF9F8'
const _accent = '#F57027'
const _accentLight = '#F69D50'
const _accentMoreLight = 'rgba(225, 107, 36, 0.29)'
const _accentVeryLight = '#faede6'

const _green = '#28c986'
const _greenLight = '#ebf9f8'
const _greenDark = '#02a99c'
const _greenVeryDark = '#004a44'
const _red = '#fa3d5b'
const _redLight = 'rgba(255, 0, 0, .15)'
const _pink = 'rgba(189, 31, 130, 1)'
const _orange = '#F69D50'
const _fadedOrange = '#faede6'
const _yellow = '#FABF0C'
const _yellowVeryLight = '#FFF7E1'
const _blue = '#286efa'

export const styles = {
	card: {
		boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.03), 0 6px 10px 0 rgba(0, 0, 0, 0.03)',
		padding: 13,
		borderRadius: 10,
		backgroundColor: _white,
		minHeight: 10,
		minWidth: 10,
	},
	outlineCard: {
		borderColor: 'rgba(0,0,0,0.2)',
		borderStyle: 'solid',
		borderWidth: '1px',
		padding: 20,
		borderRadius: 10,
		minHeight: 10,
		minWidth: 10,
	},

	mediumShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.06), 0 6px 20px 0 rgba(0, 0, 0, 0.03)',
	strongerShadow:
		'0 20px 32px -8px rgba(0, 0, 0, 0.2), 0 4px 8px 0 rgba(0, 0, 0, 0.03), 0 6px 20px 0 rgba(0, 0, 0, 0.03)',

	font: _font,
	fontAlt: _fontAlt,

	header: {
		color: _accentLight,
		fontFamily: _font,
		fontSize: 19,
		fontWeight: 700,
	},
	header_dash: {
		color: '#666666',
		fontFamily: _font,
		fontSize: 16,
		fontWeight: 700,
	},
	header_strong: {
		color: _accent,
		fontFamily: _font,
		fontSize: 26,
		fontWeight: 700,
	},
	header_big_landing: {
		color: _black,
		fontFamily: _font,
		fontSize: 30,
		fontWeight: 700,
		letterSpacing: '0.06px',
		lineHeight: '52px',
	},
	header_landing: {
		color: _black,
		fontFamily: _font,
		fontSize: 19,
		fontWeight: 700,
	},
	nav_selected: {
		color: _accent,
		fontFamily: _font,
		fontSize: 16,
		fontWeight: 700,
	},
	nav: {
		color: _black,
		fontFamily: _font,
		fontSize: 16,
		fontWeight: 400,
	},
	nav_selected_landing: {
		color: _black,
		fontFamily: _font,
		fontSize: 16,
		fontWeight: 700,
	},
	nav_landing: {
		color: _black,
		fontFamily: _font,
		fontSize: 16,
		fontWeight: 400,
	},
	landing: {
		fontSize: 16,
		fontFamily: _font,
		letterSpacing: '0.42px',
		lineHeight: '28px',
	},
	big: {
		fontSize: 16,
		fontFamily: _font,
		fontWeight: 700,
	},
	big_light: {
		color: _accentLight,
		fontSize: 16,
		fontFamily: _font,
		fontWeight: 700,
	},
	small: {
		fontSize: 11,
		fontFamily: _font,
		fontWeight: 400,
		opacity: 0.5,
	},
	light: {
		fontWeight: 400,
		fontFamily: _font,
		opacity: 0.5,
	},
	light_bold: {
		fontWeight: 700,
		fontFamily: _font,
		opacity: 0.5,
	},

	colors: {
		black: _black,
		white: _white,
		accent: _accent,
		accentLight: _accentLight,
		accentMoreLight: _accentMoreLight,
		accentVeryLight: _accentVeryLight,
		main: _main,
		mainLight: _mainLight,
		mainVeryLight: _mainVeryLight,
		background: _background,
		green: _green,
		greenLight: _greenLight,
		greenDark: _greenDark,
		greenVeryDark: _greenVeryDark,
		blue: _blue,
		red: _red,
		redLight: _redLight,
		pink: _pink,
		orange: _orange,
		fadedOrange: _fadedOrange,
		yellow: _yellow,
		yellowVeryLight: _yellowVeryLight,
		grey: _grey,
		greyLight: _greyLight,
		greyVeryLight: _greyVeryLight,
	},
	gradients: {
		fade: 'linear-gradient(90deg, rgba(2, 4, 51, 1), rgba(2, 4, 51, 1), rgba(2, 4, 51, 0))',
		main: _main,
	},
}

export default styles
