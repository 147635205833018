const config = require('../config')

export async function get(path, includeCredentials, signal, noAPI = false) {
	console.log('GET ' + path)
	return bodyOf(request('get', path, null, includeCredentials, signal, noAPI))
}
export async function post(path, body, includeCredentials, signal, noAPI = false) {
	console.log('POST ' + path + '\n' + JSON.stringify(body))
	return bodyOf(request('post', path, body, includeCredentials, signal, noAPI))
}
export async function put(path, body, includeCredentials, signal, noAPI = false) {
	console.log('PUT ' + path + '\n' + JSON.stringify(body))
	return bodyOf(request('put', path, body, includeCredentials, signal, noAPI))
}
export async function del(path, includeCredentials, signal, noAPI = false) {
	console.log('DELETE ' + path)
	return bodyOf(request('delete', path, null, includeCredentials, signal, noAPI))
}

export async function request(method, path, body, includeCredentials, signal, noAPI) {
	try {
		const response = await sendRequest(method, path, body, includeCredentials, signal, noAPI)
		return handleResponse(path, response)
	} catch (error) {
		throw error
	}
}

/**
 * Takes a relative path and makes it a full URL to API server
 */
export function url(path) {
	const apiRoot = config.backendURL
	return path.indexOf('/') === 0 ? apiRoot + path : apiRoot + '/' + path
}

/**
 * Constructs and fires a HTTP request
 */
async function sendRequest(method, path, body, includeCredentials, signal, noAPI) {
	try {
		const endpoint = noAPI ? path : url(path)
		var headers = getRequestHeaders(body)
		if (includeCredentials) {
			var token = localStorage.getItem('token')
			if (token) headers = { ...headers, token: token }
		}
		const options = body
			? {
					method,
					headers,
					credentials: includeCredentials ? 'include' : undefined,
					body: JSON.stringify(body),
					signal: signal,
			  }
			: {
					method,
					headers,
					credentials: includeCredentials ? 'include' : undefined,
					signal: signal,
			  }

		return fetch(endpoint, options)
	} catch (e) {
		throw e
	}
}

/**
 * Receives and reads a HTTP response
 */
async function handleResponse(path, response) {
	try {
		const responseBody = await response.text()

		var body = responseBody ? JSON.parse(responseBody) : null
		var h = global.routerHistory()
		if (
			body &&
			!body.success &&
			body.data &&
			body.data.invalidToken &&
			h &&
			h.location.pathname.includes('/dashboard')
		) {
			h.push('/login?redir=' + h.location.pathname)
		}

		return {
			status: response.status,
			headers: response.headers,
			body: body,
		}
	} catch (e) {
		console.error(e)
	}
}

function getRequestHeaders(body) {
	const headers = body
		? { Accept: 'application/json', 'Content-Type': 'application/json' }
		: { Accept: 'application/json' }

	return headers
}

async function bodyOf(requestPromise) {
	try {
		const response = await requestPromise
		return response.body
	} catch (e) {
		console.error(e)
	}
}
