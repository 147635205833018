///////////// DON'T FORGET TO ADD A REFERENCE IN reducer.js

import { get } from '../utils/api'
export const USER_FETCHED = 'user/USER_FETCHED'
const initialState = {
	data: undefined,
}

export default (state = initialState, action) => {
	switch (action.type) {
		case USER_FETCHED:
			return {
				...state,
				data: action.data,
			}

		default:
			return state
	}
}

export const fetchUser = callback => {
	return dispatch => {
		get('client/data', true)
			.then(res => {
				if (res.success) {
					res.data.admin = res.data.account.permissions.admin
					res.data.limitedAdmin = res.data.account.permissions.limitedAdmin
					res.data.onlyTracking = res.data.account.permissions.onlyTracking
					res.data.calleebree = res.data.account.permissions.calleebree
					res.data.onlyMarketing = res.data.account.permissions.onlyMarketing

					/* global.lang = {
						text: 'pt',
						moment: 'pt',
						numeral: 'us',
						date: 'pt-PT',
					}
					moment.locale(global.lang.moment)
					localStorage.setItem('lang', JSON.stringify(global.lang))
					global.forceLang = true */

					dispatch({
						type: USER_FETCHED,
						data: res.data,
					})
					if (callback) callback(res.data)

					var d = document.getElementById('tawkId')
					if (d) d.remove()

					if (window.Tawk_API)
						window.Tawk_API.visitor = {
							name: res.data.personal.firstName + ' ' + res.data.personal.lastName,
							email: res.data.email,
							// ! Should use Secure Mode: https://developer.tawk.to/set-name-email-through-the-javascript-api/
						}
					else
						window.Tawk_API = {
							visitor: {
								name:
									res.data.personal.firstName + ' ' + res.data.personal.lastName,
								email: res.data.email,
								// ! Should use Secure Mode: https://developer.tawk.to/set-name-email-through-the-javascript-api/
							},
						}
				} else {
					res.data.admin = false
					global.cookies.set('token', '', {
						path: '/',
					})
					localStorage.setItem('token', '')
					dispatch({
						type: USER_FETCHED,
						data: undefined,
					})
					if (callback) callback()
				}
			})
			.catch(e => {
				console.error(e)
				//alert(e)
				if (callback) callback()
			})
	}
}
