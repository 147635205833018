import MaterialIcon from 'material-icons-react'
import React, { Component, Suspense } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import { ErrorBoundary } from 'react-error-boundary'
import { Provider } from 'react-redux'
import MediaQuery from 'react-responsive'
import { Fade } from 'react-reveal'
import io from 'socket.io-client'
//import tawkTo from 'tawkto-react'
import Cookies from 'universal-cookie'
import logo from './assets/images/logo.png'
import styles from './assets/styles'
import CustomButton from './components/CustomButton'
import GenericModal from './components/GenericModal'
import NavigatorView from './navigation/NavigatorView'
import banner from './pages/public/main/assets/background.jpg'
import store from './redux/store'
import { get } from './utils/api'

const cookies = new Cookies()
const config = require('./config')

if (!(navigator.userAgent.includes('google') || navigator.userAgent.includes('Google'))) {
	if (window.Localize)
		window.Localize.initialize({
			key: 'FF19XrcS3hiGC',

			rememberLanguage: true,
			disableWidget: true,
			...((navigator.userAgent.includes('google') ||
				navigator.userAgent.includes('Google')) && {
				autodetectLanguage: false,
			}),
		})
	else console.log('window.Localize is not available!')
}

const forceLang =
	window.location.search &&
	(window.location.search.includes('forceLanguage=PT')
		? 'pt'
		: window.location.search.includes('forceLanguage=FR')
		? 'fr'
		: window.location.search.includes('forceLanguage=EN')
		? 'en'
		: undefined)
if (forceLang) window.Localize.setLanguage(forceLang)

global.cookies = cookies

global.genericModal = function(
	title,
	content,
	buttons,
	color,
	noPadding = false,
	customStyling = false,
	mobile = false,
	centerButton = false,
	warning,
	extraTitle,
	noMinWidth = false,
	noClose = false,
	maxWidth,
	extraProps
) {
	confirmAlert({
		willUnmount: () => {},
		onClickOutside: () => {},
		onKeypressEscape: () => {},
		closeOnEscape: false,
		closeOnClickOutside: false,
		customUI: ({ onClose }) => {
			//alert('INSIDE APP.GENERIC MODAL. COLOR? > ' + color)
			function ErrorFallback({ error, resetErrorBoundary }) {
				const chunkFailedMessage = /Loading chunk [\d]+ failed/
				if (error?.message && chunkFailedMessage.test(error.message)) {
					window.location.reload()
				}

				return <div></div>
			}
			return (
				<ErrorBoundary FallbackComponent={ErrorFallback}>
					<Suspense fallback={<div></div>}>
						<Provider store={store}>
							<GenericModal
								{...extraProps}
								noMinWidth={noMinWidth}
								customStyling={customStyling}
								onClose={onClose}
								title={title}
								buttons={buttons}
								color={color}
								content={content}
								noPadding={noPadding}
								mobile={mobile}
								centerButton={centerButton}
								noClose={noClose}
								warning={warning}
								extraTitle={extraTitle}
								maxWidth={maxWidth}
							></GenericModal>
						</Provider>
					</Suspense>
				</ErrorBoundary>
			)
		},
	})
}

export default class App extends Component {
	state = {
		socketConnectionDelay: false,
		isReconnect: false,
		oldBuild: false,
		hideWarnings: false,
		ready: false,
	}

	hideWarnings = function() {
		this.setState({ hideWarnings: true })
	}
	changeBackground = function(color) {
		document.body.style.backgroundColor = color
	}

	componentWillMount() {
		global.updateApp = function() {
			this.setState({
				random: Math.random(),
			})
		}.bind(this)

		get('/structures')
			.then(res => {
				if (res.success) {
					global.structures = res.data.structures
					this.setState({
						ready: true,
						random: Math.random(),
					})
				}
			})
			.catch(e => {})

		global.hideWarnings = this.hideWarnings.bind(this)

		global.changeBackground = this.changeBackground.bind(this)

		/* window.intercomSettings = {
			app_id: config.intercomAppID,
			alignment: 'right',
			horizontal_padding: config.intercomPaddingX,
			vertical_padding: config.intercomPaddingY,
		}
		;(function() {
			var w = window
			var ic = w.Intercom
			if (typeof ic === 'function') {
				ic('reattach_activator')
				ic('update', w.intercomSettings)
			} else {
				var d = document
				var i = function() {
					i.c(arguments)
				}
				i.q = []
				i.c = function(args) {
					i.q.push(args)
				}
				w.Intercom = i
				function l() {
					var s = d.createElement('script')
					s.type = 'text/javascript'
					s.async = true
					s.src = 'https://widget.intercom.io/widget/' + config.intercomAppID
					var x = d.getElementsByTagName('script')[0]
					x.parentNode.insertBefore(s, x)
				}
				if (w.attachEvent) {
					w.attachEvent('onload', l)
				} else {
					w.addEventListener('load', l, false)
				}
			}
		})()
		window.Intercom('boot', {
			app_id: config.intercomAppID,
		}) */

		global.socket = io(config.websocketURL)
		global.socket.on(
			'connect',
			function() {
				console.log('Socket connected: ' + global.socket.id)
				this.forceUpdate()

				var token = localStorage.getItem('token')
				global.socket.emit('init', { token: token }, res => {
					if (res.success) {
						var buildNumber = localStorage.getItem('build_number')
						localStorage.setItem('build_number', res.buildNumber)
						if (
							this.state.isReconnect &&
							buildNumber &&
							res.buildNumber !== buildNumber
						) {
							this.setState({ oldBuild: true })
						}

						if (!this.state.isReconnect) this.setState({ isReconnect: true })
					}
				})
			}.bind(this)
		)
		global.socket.on(
			'disconnect',
			function() {
				console.error('Socket disconnected!')
				this.forceUpdate()
			}.bind(this)
		)
		setTimeout(
			function() {
				this.setState({ socketConnectionDelay: true })
			}.bind(this),
			3000
		)

		/* var lang = localStorage.getItem('lang')
		if (lang) {
			global.lang = JSON.parse(lang)
			moment.locale(global.lang.moment)
		} */
	}

	componentDidMount() {
		/* const tawkToPropertyId = '612e0135d6e7610a49b2dfbe'
		// Direct Chat Link
		// https://tawk.to/chat/tawkToPropertyId/tawkToKey
		const tawkToKey = global.lang.text === 'pt' ? '1fedu9mq4' : '1fedr9eu7'

		global.tawk = new tawkTo(tawkToPropertyId, tawkToKey) */
		new Image().src = banner

		new Image().src = logo
	}

	render() {
		var cookieNotice = true //localStorage.getItem('cookie_notice') || this.state.hideWarnings

		var inDashboard = window.location.href.includes('dashboard')
		var barHeight = 30

		return (
			<MediaQuery minWidth={config.mobileWidthTrigger}>
				{desktop => {
					return (
						<div>
							{/* style={{ backgroundImage: 'url(' + pattern + ')', backgroundRepeat: 'no-repeat', backgroundSize: 'contain' }}> */}
							{inDashboard && this.state.oldBuild && !this.state.hideWarnings && (
								<div style={{ maxHeight: 0 }}>
									<Fade delay={1000} duration={500} bottom>
										<div
											style={{
												minWidth: '100vw',
												position: 'fixed',
												overflow: 'hidden',
												bottom: 0,
												background: '#4CAF50',
												minHeight: barHeight,
												maxHeight: barHeight,
												textAlign: 'center',
												zIndex: 8,
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
											}}
										>
											<p style={{ color: 'white' }}>
												{config.text('extras.newUpdate')}
											</p>
										</div>
									</Fade>
								</div>
							)}
							{inDashboard &&
								!global.socket.connected &&
								this.state.socketConnectionDelay &&
								!this.state.hideWarnings && (
									<div style={{ maxHeight: 0 }}>
										<Fade delay={1000} duration={500} bottom>
											<div
												style={{
													minWidth: '100vw',
													minHeight: barHeight,
													maxHeight: barHeight,
													position: 'fixed',
													overflow: 'hidden',
													zIndex: 8,
													background: styles.colors.red,
													textAlign: 'center',
													bottom: 0,
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
												}}
											>
												<p style={{ color: 'white' }}>
													{config.text('extras.connectionLost')}
												</p>
											</div>
										</Fade>
									</div>
								)}
							{!cookieNotice && (
								<div style={{ maxHeight: 0 }}>
									<Fade delay={1000} duration={500} bottom>
										<div
											id='cookie_banner'
											style={{
												display: 'flex',
												minWidth: '100vw',
												minHeight: desktop ? 50 : 100,
												maxHeight: desktop ? 50 : 100,
												position: 'fixed',
												overflow: 'hidden',
												bottom: 0,
												zIndex: 8,
												background: styles.colors.accent,
												textAlign: 'center',
												alignItems: 'center',
												justifyContent: 'center',
											}}
										>
											<p
												style={{
													fontSize: 12,
													opacity: 0.75,
													color: 'white',
													marginLeft: 20,
													marginBottom: 5,
													marginTop: 5,
												}}
											>
												{config.text('common.cookieWarning')}
											</p>

											<CustomButton
												id='accept_cookies'
												style={{
													marginLeft: 30,
													marginRight: 20,
													padding: 0,
													border: 'none',
													minHeight: 30,
													minWidth: 30,
													maxHeight: 30,
													maxWidth: 30,
													background: styles.colors.white,
												}}
												onClick={() => {
													localStorage.setItem('cookie_notice', true)
													this.setState({
														hideCookieNotice: true,
													})
												}}
											>
												<MaterialIcon
													icon='close'
													size='tiny'
													color={styles.colors.black}
												/>
											</CustomButton>
										</div>
									</Fade>
								</div>
							)}
							<NavigatorView ready={this.state.ready} random={this.state.random} />
							{!config.prod && (
								<div
									style={{
										position: 'fixed',
										bottom: 21,
										left: 0,
										width: '100%',
										zIndex: 100,
										maxWidth: 230,
										backgroundColor: 'transparent',
										height: 5,
										paddingLeft: 10,
										fontSize: 16,
										color: 'red',
									}}
								>
									<div
										style={{
											maxWidth: 230,
											overflow: 'hidden',
										}}
									>
										<b>{!config.staging ? 'DEVELOPMENT' : 'STAGING'}</b>

										{/* <b
										style={{
											marginLeft: 40,
											color: 'black',
											cursor: 'pointer',
										}}
										onClick={() => {
											global.lang = {
												text: 'pt',
												moment: 'pt',
												numeral: 'us',
												date: 'pt-PT',
											}
											moment.locale(global.lang.moment)
											this.setState({
												random: Math.random(),
											})
										}}
									>
										PT
									</b>
									<b
										style={{
											marginLeft: 10,
											color: 'black',
											cursor: 'pointer',
										}}
										onClick={() => {
											global.lang = {
												text: 'en',
												moment: 'en',
												numeral: 'us',
												date: 'en-US',
											}
											moment.locale(global.lang.moment)
											this.setState({
												random: Math.random(),
											})
										}}
									>
										EN
									</b> */}
									</div>
								</div>
							)}
						</div>
					)
				}}
			</MediaQuery>
		)
	}
}
