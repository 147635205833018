import React, { Component } from 'react'
import MDSpinner from 'react-md-spinner'
import styles from '../assets/styles'
import { StyledButton } from './StyledComponents'

const config = require('../config')

export default class CustomButton extends Component {
	render() {
		var mainStyle = {
			fontSize: this.props.fontSize ? this.props.fontSize : 14,
			fontFamily: styles.font,

			borderRadius: 20,
			borderStyle: 'solid',
			borderWidth: '1px',

			overflow: 'hidden',

			opacity: this.props.isDisabled ? 0.75 : '',

			cursor: this.props.isDisabled ? 'default' : 'pointer',

			minHeight: 33,
			minWidth: 100,

			padding: 12,
			paddingTop: 6,
			paddingBottom: 6,
			margin: 0,

			userSelect: this.props.selectable ? '' : 'none',
			whiteSpace: this.props.disableWhiteSpace ? undefined : 'nowrap',
			textOverflow: 'ellipsis',
		}

		mainStyle.idleColor = styles.colors.accent
		mainStyle.idleBorder = styles.colors.accent
		mainStyle.hoverBorder = styles.colors.accent
		mainStyle.activeBorder = styles.colors.accent
		mainStyle.idleBackground = 'transparent'
		mainStyle.activeBackground = 'transparent'
		mainStyle.hoverBackground = 'transparent'
		if (this.props.appearance === 'primary') {
			mainStyle.activeBackground = styles.colors.accent
			mainStyle.hoverBackground = styles.colors.accent
			mainStyle.hoverColor = styles.colors.white
			mainStyle.activeColor = styles.colors.white
		}
		if (this.props.appearance === 'alt') {
			mainStyle.activeBackground = styles.colors.main
			mainStyle.hoverBackground = styles.colors.main
			mainStyle.hoverColor = styles.colors.main
			mainStyle.activeColor = styles.colors.main
			mainStyle.color = styles.colors.main
		}

		var clean = this.props.clean
			? {
					minWidth: 1,
					minHeight: 1,
					borderStyle: 'none',
					borderRadius: 0,
					padding: 0,
					marginLeft: 10,
					paddingTop: 0,
					paddingBottom: 0,
			  }
			: {}

		var finalStyle = {
			...mainStyle,
			...clean,
			...{
				justifyContent: 'center',
				alignItems: 'center',
				display: 'flex',
				//transition: 'all .1s',
			},
			...this.props.style,
		}
		return (
			<div>
				<StyledButton
					id={this.props.id}
					onClick={this.props.onClick}
					required={this.props.required}
					value={this.props.value}
					name={this.props.name}
					type={this.props.type ? this.props.type : 'text'}
					disabled={this.props.isDisabled}
					idleColor={finalStyle.idleColor}
					idleBackground={finalStyle.idleBackground}
					idleBorder={finalStyle.idleBorder}
					activeBorder={finalStyle.activeBorder}
					activeColor={finalStyle.activeColor}
					activeBackground={finalStyle.activeBackground}
					hoverColor={!this.props.isDisabled && finalStyle.hoverColor}
					hoverBorder={!this.props.isDisabled && finalStyle.hoverBorder}
					hoverBackground={!this.props.isDisabled && finalStyle.hoverBackground}
					style={finalStyle}
				>
					{this.props.isLoading ? (
						<MDSpinner
							size={config.spinnerSmall.size}
							borderSize={config.spinnerSmall.border}
							singleColor={finalStyle.color}
						/>
					) : (
						this.props.children
					)}
				</StyledButton>
				{this.props.invalid && <div style={{ minHeight: 5 }}></div>}
				{this.props.invalid && (
					<p style={{ fontSize: 12, color: styles.colors.red }}>{this.props.invalid}</p>
				)}
			</div>
		)
	}
}
