import React, { Component } from 'react'
import { Animated } from 'react-animated-css'
import MediaQuery from 'react-responsive'
import styles from '../assets/styles'
import CustomButton from './CustomButton'
import ModalHeader from './ModalHeader'
const config = require('../config')

export default class GenericModal extends Component {
	componentWillMount() {
		global.closeCurrentModal = () => this.props.onClose()
	}

	render() {
		return (
			<Animated animationIn='fadeInRight'>
				<div
					style={{
						...styles.card,
						...{
							boxShadow: styles.strongerShadow,
							overflowY: this.props.overflowEnabled ? '' : 'auto',
							margin: 0,
							borderRadius: 8,
							maxWidth: this.props.maxWidth ? this.props.maxWidth : 1100,
							minHeight: 20,
							minWidth: this.props.noMinWidth ? '' : this.props.mobile ? 320 : 500,
							//width: this.props.width && this.props.width,
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-between',
							padding: 0,
							background: 'white',
						},
					}}
				>
					<ModalHeader
						customStyling={this.props.customStyling}
						color={this.props.color}
						title={this.props.title}
						extraTitle={this.props.extraTitle}
						onClose={this.props.onClose}
						noClose={this.props.noClose}
					/>

					<div style={{ padding: this.props.noPadding ? 0 : 20 }}>
						{this.props.content}
					</div>

					{this.props.buttons && this.props.buttons.length > 0 && (
						<div
							style={{
								display: 'flex',
								justifyContent:
									this.props.centerButton || this.props.warning
										? 'center'
										: 'flex-end',
								alignItems: this.props.warning && 'center',
								flexDirection: this.props.warning && 'column',
								padding: 20,
								boxShadow: '0 -4px 15px 0 rgba(219, 219, 219, 0.5)',
							}}
						>
							<div style={{ minHeight: !this.props.warning && 10 }} />
							{this.props.warning && (
								<MediaQuery minWidth={config.mobileWidthTrigger}>
									{desktop => (
										<center
											style={{
												display: desktop && 'flex',
												justifyContent: desktop && 'flex-start',
												marginLeft: desktop && 15,
												fontStyle: 'italic',
												fontSize: 9,
												minWidth: desktop && '100%',
												maxWidth: !desktop && 274,
												lineHeight: 1.64,
												color: '#7f7f7f',
											}}
										>
											{this.props.warning}
										</center>
									)}
								</MediaQuery>
							)}
							<div style={{ minHeight: 10 }} />
							{this.props.buttons &&
								this.props.buttons.map((b, i) => (
									<div key={i} style={{ marginLeft: !this.props.warning && 15 }}>
										<CustomButton
											appearance={b.appearance || undefined}
											type={b.submit ? 'submit' : undefined}
											style={b.style}
											isLoading={false}
											onClick={
												b.cancel
													? () => {
															this.props.onClose()
															b.action && b.action()
													  }
													: b.action
											}
										>
											{b.cancel && !b.title
												? config.text('common.cancel')
												: b.title}
										</CustomButton>
									</div>
								))}
						</div>
					)}
				</div>
			</Animated>
		)
	}
}
