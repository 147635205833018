import React from 'react'

export default ({ children, opacity, mode, width, height, color }) =>
	mode === 'vertical' ? (
		<hr
			style={{
				...{
					display: 'flex',
					height: height ? height : '99.5%',
					width: 0,
					borderStyle: color ? 'solid' : '',
					opacity: color ? 1 : opacity ? opacity : 0.15,
					borderColor: color ? color : '',
					marginBottom: 0,
				},
			}}
		>
			{children}
		</hr>
	) : (
		<hr
			style={{
				...{
					display: 'flex',
					width: width ? width : '99.5%',
					borderStyle: color ? 'solid' : '',
					opacity: color ? 1 : opacity ? opacity : 0.15,
					borderColor: color ? color : '',
					marginBottom: 0,
				},
			}}
		>
			{children}
		</hr>
	)
