import * as Sentry from '@sentry/react'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { ErrorBoundary } from 'react-error-boundary'
import { Provider } from 'react-redux'
import App from './App'
import './assets/main.css'
import store from './redux/store'
import { unregister } from './utils/registerServiceWorker'

const config = require('./config')

if (config.prod) {
	Sentry.init({
		dsn: 'https://11df7086a2e9416eb1e0cd29dcf77d3e@o94308.ingest.sentry.io/5341265',
		/* integrations: [new TracingIntegrations.BrowserTracing()],
		// Leaving the sample rate at 1.0 means that automatic instrumentation will send a transaction each time a user loads any page or navigates anywhere in your app, which is a lot of transactions. Sampling enables you to collect representative data without overwhelming either your system or your Sentry transaction quota.
		tracesSampleRate: 0.2, */
	})
	global.Sentry = Sentry
}

function ErrorFallback({ error, resetErrorBoundary }) {
	const chunkFailedMessage = /Loading chunk [\d]+ failed/
	if (error?.message && chunkFailedMessage.test(error.message)) {
		window.location.reload()
	}

	return <div></div>
}
ReactDOM.render(
	<ErrorBoundary FallbackComponent={ErrorFallback}>
		<Suspense fallback={<div></div>}>
			<Provider store={store}>
				<App></App>
			</Provider>
		</Suspense>
	</ErrorBoundary>,
	document.getElementById('root')
)

// Don't cache since after every update index.html will point to old build and users have to refresh again
unregister() //registerServiceWorker();
