import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

class ScrollToTop extends Component {
	scrollToTop() {
		window.scrollTo(0, 0)
	}
	componentWillMount() {
		global.scrollToTop = this.scrollToTop
	}

	componentDidUpdate(prevProps) {
		if (this.props.location !== prevProps.location) {
			this.scrollToTop()
		}
	}

	render() {
		return this.props.children
	}
}
export default withRouter(ScrollToTop)
